import type {SerializeFrom} from '@remix-run/node'
import {useMatches} from '@remix-run/react'
import {useMemo} from 'react'
import type {RouteData, RouteID} from '~/routes'

export function useParentLoaderData<ID extends RouteID, LoaderData = RouteData[ID]['LoaderData']>(
  id: ID,
): SerializeFrom<LoaderData> | null {
  const matches = useMatches()
  return useMemo<SerializeFrom<LoaderData> | null>(() => {
    const data = matches.find((route) => route.id === id)?.data as SerializeFrom<LoaderData> | undefined
    return data ?? null
  }, [matches, id])
}
